export default function UnreadMark() {
  return (
    <span className='UnreadMark' aria-hidden='true'>
      <span className='UnreadMark__circle' />
      <span className='UnreadMark__pulse' />

      <style jsx>{`
        .UnreadMark {
          position: relative;
        }

        .UnreadMark__circle {
          position: absolute;
          width: 12px;
          height: 12px;
          background-color: var(--uiColors-UnreadMark);
          border-radius: 50%;
          z-index: 2;
        }

        .UnreadMark__pulse {
          content: '';
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          z-index: 1;
          animation: pulse 1s ease-in-out 2s 3;
        }

        @keyframes pulse {
          0% {
            transform: scale(0.9);
            opacity: 1;
          }
          100% {
            transform: scale(2);
            opacity: 0;
          }
        }
      `}</style>
    </span>
  );
}
