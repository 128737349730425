  import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { RSAAResultAction } from "redux-api-middleware";
import { getUrlString } from "src/modules/Categories";
import { CategoryProducts } from "src/redux/apiTypes";
import { ReduxThunkAction } from "src/redux/reducers";

  interface RequestCategoryAction {
    (searchString: string): ReduxThunkAction<RSAAResultAction<CategoryProducts, never>>;
  }

  interface Params {
    skipFirstRender?: boolean;
    updateAction: RequestCategoryAction;
  }

  const useUpdateB2BCategoryData = ({
    skipFirstRender = false,
    updateAction,
  }: Params) => {
    const skipFirstRenderRef = useRef<boolean>(skipFirstRender);
    const dispatch = useDispatch();
    const { query } = useRouter();

    const { isLoadMore, ...cleanQuery } = query;
    const trackedUrlString = getUrlString(cleanQuery);

    useEffect(() => {
      if (isLoadMore === "true") return;

      if (!skipFirstRenderRef.current) {
        dispatch(updateAction(trackedUrlString));
      } else {
        skipFirstRenderRef.current = false;
      }
    }, [dispatch, trackedUrlString, isLoadMore, updateAction]);
  };

  export default useUpdateB2BCategoryData;
