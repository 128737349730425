import cn from 'classnames';
import { ChangeEventHandler, Fragment, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import Button from 'src/components/ui-kit/Button/Button';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { Filter, Option } from '../../../redux/apiTypes';
import { HandleFilterFunc } from '../../containers/CatalogFilters/CatalogFilters';
import CheckListItem from '../../containers/CheckListItem/CheckListItem';
import FilterPriceWrap from '../FilterPriceWrap/FilterPriceWrap';
import { checkListItemsStyles, styles } from './CheckList.styles';

export interface CheckListProps {
  isFetchingProducts?: boolean;
  isMobileMode?: boolean;
  hasSearch?: boolean;
  itemsAsLinks?: boolean;
  filter: Filter;
  handleFilter: HandleFilterFunc;
  excludeParams?: string[];
  setPriceUpdate?(): void;
}

const CheckList = (props:CheckListProps) => {
  const {
    isFetchingProducts,
    isMobileMode,
    hasSearch,
    filter,
    handleFilter,
    excludeParams,
    itemsAsLinks,
  } = props;

  const { settings: { DEFAULT_VISIBLE_FILTERS_COUNT } } = useAppConfig();

  const [searchWord, setSearchWord] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const localize = useLocalization();
  const isSearchMode = searchWord !== '';
  const options: Option[] = filter.type !=='range' ? filter.options : [];
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChangeSearchWord: ChangeEventHandler<HTMLInputElement> = event => {
    setSearchWord(event.target.value);
  };

  const handleCollapse = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const handleCancelSearch = () => {
    setSearchWord('');
    inputRef.current?.blur();
  };

  const renderEmptyListMessage = () => (
    <div className='CheckList__emptyListMessage'>
      <span>{ localize('filters.empty') }</span>
      <style jsx>{ checkListItemsStyles }</style>
    </div>
  );

  const getFilteredOptions = () => {
    const filteredOptions = isSearchMode
      ? options.filter(option => {
          return option.name.toUpperCase().indexOf(searchWord.toUpperCase()) !== -1;
        })
      : options;
    return filteredOptions;
  };

  const renderFilterOptions = () => {
    const preparedOptions = getFilteredOptions();

    if (preparedOptions.length === 0) {
      return renderEmptyListMessage();
    }

    // when searchMode is active or length of preparedOptions less
    // than DEFAULT_VISIBLE_FILTERS_COUNT render simple CheckListItem
    if (preparedOptions.length <= DEFAULT_VISIBLE_FILTERS_COUNT || isSearchMode) {
      return (
        <div
          className='CheckList__items'
          data-testid="check-list-desktop-full"
        >
          {preparedOptions.map(option => (
            <CheckListItem
              key={option.name}
              filter={filter}
              option={option}
              handleFilter={handleFilter}
              isLink={itemsAsLinks}
            />
          ))}
          <style jsx>{ checkListItemsStyles }</style>
        </div>
      );
    }

    return (
      <Fragment>
        <div
          className='CheckList__items'
          data-testid="check-list-desktop"
        >
          {preparedOptions
            .slice(0, DEFAULT_VISIBLE_FILTERS_COUNT)
            .map(option => (
              <CheckListItem
                key={option.name}
                filter={filter}
                option={option}
                handleFilter={handleFilter}
                isLink={itemsAsLinks}
              />
            ))
          }
          <Collapse
            isOpened={ isOpen }
          >
            {preparedOptions
              .slice(DEFAULT_VISIBLE_FILTERS_COUNT)
              .map(option => (
                <CheckListItem
                  key={option.name}
                  filter={filter}
                  option={option}
                  handleFilter={handleFilter}
                  isLink={itemsAsLinks}
                />
              ))
            }
          </Collapse>
        </div>
        <Button
          type='button'
          dataMarkerValue='Show more'
          onClick={handleCollapse}
          variant='ghost'
          regularText
        >
          {localize(isOpen ? 'products.show-less' : 'products.show-more')}
          <span
            className={cn('CheckList__collapseButtonIcon', {
              'icon-caret-bottom': !isOpen,
              'icon-caret-top': isOpen,
            })}
          />
        </Button>
        <style jsx>{ checkListItemsStyles }</style>
      </Fragment>
    );
  };

  const renderFilterOptionsMobile = () => {
    const preparedOptions = getFilteredOptions();

    if(preparedOptions.length === 0) {
      if (filter.key !== 'price') {
        return renderEmptyListMessage();
      }

      if (filter.type === 'range') {
        return (
          <FilterPriceWrap
          filter={filter}
          excludeParams={excludeParams}
          isMobile
          />
        );
      }
    }

    return (
      <ul
        className='CheckList__items'
        data-testid="check-list-mobile"
      >
        {preparedOptions.map(option => (
          <li key={option.value} className='CheckList__item'>
            <CheckListItem
              filter={filter}
              option={option}
              handleFilter={handleFilter}
            />
          </li>
        ))}
        <style jsx>{checkListItemsStyles}</style>
      </ul>
    );
  };

  return (
    <div
      className={cn('CheckList', {
        CheckList_loading: isFetchingProducts,
      })}
    >
      {hasSearch && filter.key !== 'price' && (
        <div className='CheckList__search'>
          <input
            className='CheckList__searchField form-control'
            type='text'
            value={searchWord}
            onChange={handleChangeSearchWord}
            data-marker='Check list search field'
            placeholder={localize('search')}
            ref={inputRef}
            data-testid="search-field"
          />

          {searchWord.length > 0 ? (
            <button
              className='CheckList__icon CheckList__icon_clear icon-close'
              type='button'
              onClick={handleCancelSearch}
              data-marker="Filter_cross_clear"
            />
          ) : (
            <span
              className='CheckList__icon SearchBox__icon_search icon-loupe'
              data-marker="Filter_search"
            />
          )}

        </div>
      )}

      {!filter
        ? null
        : isMobileMode
          ? renderFilterOptionsMobile()
          : renderFilterOptions()
      }

      <style jsx>{styles}</style>
    </div>
  );
};

export default CheckList;
