import cn from 'classnames';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { Filter, Option } from '../../../redux/apiTypes';
import getLinkToFilterPage from '../../../utils/filter/getLinkToFilterPage';
import getQueryWithMergedFilterParams from '../../../utils/filter/getQueryWithMergedFilterParams';
import { HandleFilterFunc } from '../CatalogFilters/CatalogFilters';
import { styles } from './CheckListItem.styles';
import CheckboxOptionAmounted from './CheckboxOptionAmounted/CheckboxOptionAmounted';

export interface CheckListItemProps {
  filter: Filter;
  option: Option;
  isLink?: boolean;
  fullWidth?: boolean;
  handleFilter: HandleFilterFunc;
}

export default function CheckListItem(props: CheckListItemProps) {
  const { filter, option, handleFilter, fullWidth, isLink = true } = props;
  const router = useRouter();
  const { query } = router;

  const searchArray: string[] = [];

  const queryWithMergedFilterParams = getQueryWithMergedFilterParams(query);

  Object.keys(queryWithMergedFilterParams).forEach(key => {
    if (Array.isArray(queryWithMergedFilterParams[key])) {
      queryWithMergedFilterParams[key].forEach(val => {
        searchArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
      });
    } else {
      searchArray
        .push(`${encodeURIComponent(key)}=${encodeURIComponent(queryWithMergedFilterParams[key])}`);
    }
  });


  if (filter.key && option.name && option.value) {
    const isChecked = searchArray.indexOf(
      `${encodeURIComponent(filter.key)}=${encodeURIComponent(option.value)}`,
    ) !== -1;

    const checkboxElement = (
      <CheckboxOptionAmounted
        text={ option.name }
        amount={ option.count >= 0 ? option.count : undefined }
        labelId={ option.value }
        checked={ isChecked }
        disabled={ option.count === 0 && !isChecked }
        onClick={ handleFilter(filter.key, option.value) }
      />
    );

    const linkToFilterPage = getLinkToFilterPage(filter.key, option.value, router);
    const renderItemAsLink = isLink && linkToFilterPage;

    return (
      <Fragment>
        {
          renderItemAsLink ? (
            <a
              href={linkToFilterPage}
              onClick={e => e.preventDefault()}
              className={cn(
                'CheckListItem',
                'CheckListItem_link', {
                'CheckListItem_fullWidth': fullWidth,
              })}
            >
              {checkboxElement}
            </a>
          ) : (
            <div className={cn(
              'CheckListItem', {
              'CheckListItem_fullWidth': fullWidth,
              })}
            >
              {checkboxElement}
            </div>
          )
        }
        <style jsx>{styles}</style>
      </Fragment>
    );
  }

  return null;
}
