import { phone_tablet } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';
import { headerZIndex } from '../../headerZIndex';

export const styles = css`
  .AccountNavigationDrop {
    position: relative;
  }
  .AccountNavigationDrop__button {
    ${typography.h9}
    line-height: 1;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .AccountNavigationDrop__button:hover .AccountNavigationDrop__account {
    color: var(--colors-linkAccented);
  }
  .AccountNavigationDrop__button_green .AccountNavigationDrop__icon,
  .AccountNavigationDrop__button_green {
    color: var(--colors-zakazGreen);
    font-weight: 600;
  }
  .AccountNavigationDrop__icon {
    color: var(--colors-brandLightGrey8);
    font-size: 16px;
  }
  .AccountNavigationDrop__iconCaret {
    color: var(--colors-brandLightGrey8);
    font-size: 8px;
    margin-left: 5px;
  }
  .AccountNavigationDrop__iconCaret_open {
    color: var(--colors-linkAccented);
  }
  .AccountNavigationDrop__account {
    margin-left: 4px;
    display: inline-block;
  }
  .AccountNavigationDrop__dropdown {
    position: absolute;
    top: calc(100% + 24px);
    right: 0;
    width: 265px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--colors-white);
    z-index: ${headerZIndex.indexOf('accountDropdown')};
  }
  .AccountNavigationDrop__dropdown:after{
    content: '';
    position: absolute;
    top: -9px;
    right: 7px;
    width: 13px;
    height: 10px;
    background: var(--colors-white);
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  }

  @media ${phone_tablet} {
    .AccountNavigationDrop__dropdown {
      top: calc(100% + 16px);
    }

    .AccountNavigationDrop__dropdown:after {
      right: 5px;
    }

    .AccountNavigationDrop__icon_active {
      color: var(--colors-mainBrand);
    }
  }
`;
